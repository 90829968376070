@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;900&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

:root {
  --bg-color: rgba(255, 255, 255, 0.188);
  --on-bg-color: rgb(255, 255, 255);
  --drop-color: #f3f3f3;
  --button-text-color: #fff7cc;
  --header-color: linear-gradient(to top, transparent, #fff5baa2 20px);
  --mobile-header-color: linear-gradient(to top, transparent, #fff5bae0 0px);
  --header-drop-color: #fff09cd5;
  --header-drop-active-color: #eee196;
  --header-drop-content-color: #fff7c6;
  --header-drop-hover-color: #fff198;
  --tab-content-color: #e8e8e871;
  --current-search-query-color: #FCFFAE;
  --footer-bg-color: #fec72e8b;
  --footer-text-color: #FFF5BA;
  --pagination-h3-color: #f8f9fa;
  --color: #fff;
  --color-text: #000000;
  --pagination-button-text-disabled: #eeeeee;
  --popup-bg-color: #fff9db;
  --cookies-bg-color: #fff9db;
  --popup-header-color: #ffddad;
  --popup-on-bg-color: #ffe3a7;
  --tab-on-bg-color: #eaeaea;
  --autofill-text-color: #fff7e0;
  --news-text-color: #fffefc;
  --dropdown-art-active-color: #dedede;
  --dropdown-content-hover-color: #f1f1f1;
  --popup-outer: rgba(0, 0, 0, 0.521);
  --filter-sheet-bg-color: rgb(250, 239, 219);
  --filter-sheet-header-divider: rgb(219, 206, 185);
  --filter-sheet-content-h3: rgb(251, 232, 199);
  --filter-sheet-chip-border: #c8c8c8;
  --filter-sheet-chip-color: rgb(255, 255, 255);
  --filter-sheet-slider-label: rgb(244, 228, 198);
  --progress-bar-bg: #e0e0df;
  --footer-icon-color: #fff9dc;
  --nav-bar-bg: #fff8cf;
  --main-title-heading: rgb(111, 174, 148);
  --main-title-heading-bg: rgba(255, 239, 176, 0.209);
  --mobile-header-links-icons: #fff4ca52;
  --mobile-header-color-2: #f2e5acb7;
  --mobile-header-color-1: #ffe5862f;
  --mobile-header-color-3: #fffcf256;
  --filter-button-border: #a1a1a16a;
}

/* Dark Theme */
[data-theme="dark"] {
  --bg-color: rgb(25, 25, 25);
  --on-bg-color: rgb(43, 43, 43);
  --drop-color: #212121;
  --button-text-color: rgb(36, 36, 36);
  --header-color: linear-gradient(to top, transparent, #00000095 70px);
  --mobile-header-color: linear-gradient(to top, transparent, #000000a2 0px);
  --header-drop-color: #1f1f1f;
  --header-drop-active-color: #202020;
  --header-drop-content-color: #353535;
  --header-drop-hover-color: #3b3b3b;
  --tab-content-color: #38383871;
  --current-search-query-color: #56573a7d;
  --footer-bg-color: #080808;
  --footer-text-color: #47927E;
  --pagination-h3-color: #373737;
  --color: #000000;
  --color-text: #c0c0c0;
  --pagination-button-text-disabled: #3c3c3c;
  --popup-bg-color: #151515a9;
  --cookies-bg-color: #151515;
  --popup-header-color: #191919e8;
  --popup-on-bg-color: #363636;
  --tab-on-bg-color: #363636;
  --autofill-text-color: #404040;
  --news-text-color: #232323;
  --dropdown-art-active-color: #1b1b1b;
  --dropdown-content-hover-color: #181818;
  --popup-outer: rgba(0, 0, 0, 0.829);
  --filter-sheet-bg-color: rgb(28, 28, 28);
  --filter-sheet-header-divider: rgb(77, 77, 77);
  --filter-sheet-content-h3: #292929;
  --filter-sheet-chip-border: #949494;
  --filter-sheet-chip-color: rgb(51, 51, 51);
  --filter-sheet-slider-label: #4a4a4a;
  --progress-bar-bg: #636363;
  --footer-icon-color: #47927E;
  --nav-bar-bg: #121212;
  --main-title-heading: rgb(111, 174, 148);
  --main-title-heading-bg: rgba(91, 91, 91, 0.209);
  --mobile-header-links-icons: #7e7e7e52;
  --mobile-header-color-2: #303030b7;
  --mobile-header-color-1: #ff0000;
  --mobile-header-color-3: #17171756;
  --filter-button-color: #444444;
}

[class="body-no-scroll"] {
  --position: fixed;
}

body,
#root {
  position: var(--body-no-scroll);
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 100%;
  color: #47927E;
  overflow-x: hidden;
  background-color: var(--bg-color);
}

.App {
  position: var(--body-no-scroll);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


header {
  background: var(--header-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* adjust as needed */
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 30px 40px;
}

/* Style The Dropdown Button */
header .dropbtn {
  background-color: var(--header-drop-color);
  padding-right: 35px;
  min-width: 7vh;
  max-height: 10vh;
  font-size: 15px;

  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-delay: 2s;
  animation-iteration-count: 1;
  animation-name: popUp;
}

/* Dropdown Content (Hidden by Default) */
header .dropdown-content {
  width: 50px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: var(--header-drop-content-color);
  font-size: 15px;
}

/* Links inside the dropdown */
header .dropdown-content a {
  color: black;
  padding: 12px 8px !important;
  text-decoration: none;
  display: block;
  text-align: center !important;
  font-size: 15px;
  width: 100%;
  margin-right: 0px !important;
  border-radius: 5px;
}

/* Change color of dropdown links on hover */
header .dropdown-content a:hover {
  background-color: var(--header-drop-hover-color)
}

/* Change the background color of the dropdown button when the dropdown content is shown */
header .dropbtn.active {
  background-color: var(--header-drop-active-color);
}

.logo-image {
  display: none;

  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-name: popUp;
}

#logo img {
  aspect-ratio: 4 / 1;
  width: 120px;
  /* adjust as needed */
  margin: auto;
  display: block;
  cursor: pointer;

  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-name: popUp;
}

#logo-icon img {
  aspect-ratio: 4 / 1;
  width: 80px;
  /* adjust as needed */
  margin: auto;
  display: block;
  cursor: pointer;

  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-name: popUp;
}


#header-items {
  font-weight: 700;
  display: flex;
  font-size: 1.2em;
  align-items: center;
  color: #47927E;
}

#header-items a {
  color: #47927E;
  /* Change the color to whatever you want the links to be */
  text-decoration: none;
  /* Removes the underline */
  margin-right: 30px;
  transition: all 0.3s ease;
  /* Adds space to the right of each link */
}

#header-items a:hover {
  color: #2aaf8c;
  transition: all 0.3s ease;
}

#header-items a:last-child {
  margin-right: 0;
  /* Removes the right margin from the last link */
}

#header-items i {
  margin-right: 5px;
}




main {
  padding: 0px;
  text-align: center;
  width: 100%;
  position: relative;
  /* Establish a new stacking context for z-index */
}

#svg-background {
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 55vh;
  background-image: url('../public/images/background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  /* Put the background behind all other content */
}

#svg-background::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 15%;
  /* adjust as needed */
  width: 100%;
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}


#search-section {
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
}


#main-title-section {
  display: flex;
  /* Changes the direction */
  align-items: stretch;
  justify-content: center;
  top: 10px;
  /* Aligns items horizontally */
  position: relative;
  /* Establishes the context for absolute positioning of child elements */
}


#main-title h2 {
  display: block;
  padding-right: 10px;
  color: #47927E;
  font-size: 2.5em;
  position: relative;
  transition: transform 0.5s ease-in-out, margin-left 0.5s ease-in-out;
  /* Adjust the duration as needed */
}


#sliding-header h2 {
  /* Adjust these as necessary */
  display: flex;
  width: fit-content;
  /* or inline-flex if you need flex properties */
  /* This makes the h2 positioned relative to the #main-title element */
  font-size: 2.5em;
}

.slide-enter {
  position: absolute;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 1s, transform 1s;
  transition-delay: 0.5s;
  /* add delay here */
}

.slide-enter-active {
  position: absolute;
  opacity: 1;
  transform: translateY(0);
}

.slide-exit {
  position: relative;
  opacity: 1;
  transform: translateY(0);
}

.slide-exit-active {
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s, transform 1s;
}

#search-input-combined {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--on-bg-color);
  border-radius: 25px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  padding: 10px 0px;

  max-width: 900px;
  /* adjust as needed */

  margin-top: 70px;
}

#search-input-art,
#search-input-artist,
#search-input-location {
  border: none;
  padding: 10px 5px;
  /* adjust as needed */
  font-size: 1.2em;
  /* adjust as needed */
  background-color: var(--on-bg-color);
  color: var(--color-text);
  min-width: 50%;
}

#search-input-location {
  max-width: 100%;
}

#search-input-art,
#search-input-artist {
  max-width: 220px;
}


#search-input-art:focus,
#search-input-artist:focus,
#search-input-location:focus {
  outline: none;
  /* Removes the default focus outline (optional) */
  border-color: transparent;
  /* Set the border color to transparent when focused */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000000;
  -webkit-box-shadow: 0 0 0px 1000px var(--autofill-text-color) inset;
  transition: background-color 1000s ease-in-out 0s;
}

input::selection {
  background-color: #62c4aa4d;
}

/* Style The Dropdown Button */
.dropbtn {
  background-color: var(--drop-color);
  color: #47927E;
  padding: 12px;
  min-width: 20vh;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--color);
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: var(--color-text);
  padding: 12px 5px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  border-radius: 8px;
  background-color: var(--dropdown-content-hover-color)
}

/* Show the dropdown menu when state is active */
.dropdown-content {
  display: none;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropbtn.active {
  background-color: var(--dropdown-art-active-color);
}

.dropbtn::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  /* Adjust the position as desired */
  transform: translateY(-50%) rotate(45deg);
  transition: transform 0.3s ease;
  border: solid #47927E;
  border-width: 0 3px 3px 0;
  padding: 3px;
  display: inline-block;
  pointer-events: none;
}

.dropbtn.active::after {
  transform: translateY(-50%) rotate(225deg);
  /* Rotate by 90 degrees */
}



#search-divider {
  width: 1px;
  height: 30px;
  background-color: #47927E;
  margin: 0 5px;
}

#search-divider-section {
  padding: 0 10px;
}

.search-inputs {
  max-width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* Add other styles as required */
}

.search-items {
  width: 95%;
  margin: 0 -10px;
  gap: 5px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
}


#search-button {
  background-color: #47927E;
  border: none;
  color: var(--button-text-color);
  padding: 12px 24px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  margin-right: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-delay: 1.3s;
  animation-iteration-count: 1;
  animation-name: popUp;
}

#search-button:hover {
  transition: background-color 0.3s ease;
  background-color: #53ab94;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

#search-button:active {
  transition: background-color 0.3s ease;
  background-color: #377061;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
}

#location-button {
  background-color: #FCC62E;
  border: none;
  color: var(--button-text-color);
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

#location-button:hover {
  background-color: #fdcd47;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

#location-button:active {
  background-color: #ddb33f;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
}

#results-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  margin-top: 0px;
  padding: 0%;
}

#current-search-query-online,
#current-search-query-offline {
  display: inline-flex;
  gap: 5px;
  /* changed from block to flex */
  flex-direction: row;
  justify-content: center;
  /* This will center the child horizontally */
  align-items: center;
  /* This will center the child vertically */
  flex-wrap: wrap;
  margin-bottom: 0px;
  text-align: center;
}

#current-search-query-offline h2 {
  background-color: rgba(206, 16, 16, 0.62);
  margin-right: 0px;
  color: #FEC52E;
  font-weight: 600;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  text-align: center;

  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-iteration-count: 1;
  animation-name: popUp;
}

#current-search-query-online h2 {
  margin-right: 0px;
  background-color: var(--current-search-query-color);
  color: #FEC52E;
  font-weight: 600;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.artwork-card {
  background-color: #f58c2a43;
  border-radius: 18px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  color: #FFF5BA;
  width: 300px;
  /* This sets the width of each card */
  height: 250px;
  transform: scale(1);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.artist-card {
  background-color: #72e7c884;
  border-radius: 18px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  color: #baffd0;
  width: 300px;
  /* This sets the width of each card */
  height: 250px;
  transform: scale(1);
  transition: transform 0.3s ease;
  cursor: pointer;
  border: none;
}

.artwork-card:hover {
  box-shadow: 5px 15px 20px rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
  transition: transform 0.3s, 0.3s ease;
}

.artist-card:hover {
  box-shadow: 5px 15px 20px rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
  transition: transform 0.3s, 0.3s ease;
}

.artwork-card img {
  margin-top: 0px;
  margin-bottom: 4px;
  width: 270px;
  height: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 9px;
  /* You can adjust the radius as needed */
}

.artist-card img {
  margin-top: -19px;
  margin-bottom: 4px;
  width: 270px;
  height: 150px;
  object-fit: cover;
  object-position: top;
  border-radius: 9px;
  /* You can adjust the radius as needed */
}

.loading-image-placeholder-artwork {
  left: 20%;
  right: 20%;
  margin: auto auto auto;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 8px;
  width: 270px;
  height: 150px;
  object-fit: cover;
  object-position: top;
  border-radius: 9px;
  /* You can adjust the radius as needed */
  background: #8282827d
}

.loading-slideshow-image {
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
  height: 200px;
  width: 300px;
  /* You can adjust the radius as needed */
  background: #82828238
}

.slideshow-loading-spinner {
  position: relative;
  top: 45%;
  left: 45%;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top: 3px solid #F48E2A;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.loading-image-placeholder-artist {
  left: 20%;
  right: 20%;
  display: flex;
  justify-content: center;
  margin: 0 auto auto auto;
  margin-top: -19px;
  width: 270px;
  height: 150px;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
  /* You can adjust the radius as needed */
  background: #8282827d
}

.artwork-card h3 {
  color: #47927E;
  margin-bottom: 7px;
  font-size: 18px;
}

.artist-card h3 {
  color: #F48E2A;
  margin-bottom: 7px;
  font-size: 18px;
}

.artwork-card p {
  color: #F48E2A;
  font-size: 16px;
}

.artist-card p {
  color: #25ac68;
  font-size: 16px;
}

.loading-section {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.loading-spinner {
  border: 5px solid rgba(0, 0, 0, 0.3);
  border-top: 5px solid #47927E;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  animation: spin 1s linear infinite;
}

.cancel-button {
  padding: 8px 15px;
  margin-top: 30px;
  color: var(--color);
  border-radius: 50px;
  border: none;
  background: rgb(221, 83, 29);
  cursor: pointer;
  font-size: medium;
  font-weight: 900;
  box-shadow: 0 5px 10px rgba(117, 117, 117, 0.103);
}

.link-button-section {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.wiki-button {
  background-color: #f4f3f1;
  border: none;
  color: black;
  padding: 13px 13px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 30px;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.maps-button {
  background-color: #f4f3f1;
  border: none;
  color: black;
  padding: 13px 13px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 30px;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.wiki-button svg {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.see-more-section {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 40px;
}

.see-more-button {
  padding: 8px 15px;
  margin-top: 10px;
  color: var(--color);
  border-radius: 50px;
  border: none;
  background: #e3b656;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 600;
  box-shadow: 0 5px 10px rgba(117, 117, 117, 0.103);
}

.cancel-button:active {
  background: rgb(184, 63, 15);
  box-shadow: 0 0px 5px rgba(117, 117, 117, 0.103);
}

.cancel-button:hover {
  background: rgb(235, 89, 31);
  box-shadow: 0 10px 20px rgba(117, 117, 117, 0.103);
}

.image-loading-spinner {
  margin-top: 23%;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top: 3px solid #F48E2A;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  background: #ffffff00;
  width: 5px;
  height: 5px;
  z-index: 10;
}

.popup ::-webkit-scrollbar {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background-color: #669488;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #47927E;
}

* {
  scrollbar-color: #669488 #FFF5BA;
  /* thumb color, track color */
  scrollbar-width: thin;
}

.hidden {
  visibility: hidden;
}

.show {
  visibility: visible;
}

.no-results-section {
  margin-top: 0px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.no-results-section .recommendations {
  display: flex;                /* Added this line to make .recommendations a flex container */
  justify-content: left;   
  align-items: center;   /* Horizontally center its children */
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 10px;
}

.no-results-section #recommendation-button {
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #ffc850;
  border: none;
  color: var(--button-text-color);
  padding: 6px 12px;
  margin: 4px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}


.no-results-section h3 {
  min-width: 200px;
  font-size: 20px;
  margin-right: 10px;
}

.filter-section {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.filter-section .filters {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 10px;
}

.filter-section #filter-button {
  background-color: var(--on-bg-color);
  border: 1px solid var(--filter-button-border);
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--button-color);
  padding: 6px 12px;
  margin: 4px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
}

.filter-section h3 {
  min-width: 200px;
  font-size: 24px;
  margin-right: 10px;
}

/* TabSection.css */

.tab-section {
  padding-top: 2%;
  padding-bottom: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}



.tab-section h3 {
  align-items: center;
  width: 100%;
  border-radius: 20px;
  color: #65a594;
  background: var(--tab-on-bg-color);
}

.tab-section-dropbutton {
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 100%;
  border-radius: 20px;
  color: #e1b045;
  cursor: pointer;
}

.tab-drop-header-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  font-size: 1.2em;
}

.tab-section-dropbutton::after {
  content: "";
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  right: 25px;
  height: 100%;
  /* Adjust the position as desired */
  transform: translateY(-50%) rotate(45deg);
  transition: transform 0.3s ease;
  border: solid #f0c463;
  border-width: 0 3px 3px 0;
  padding: 3px;
  display: inline-block;
  pointer-events: none;
}

.badge {
  background: var(--dropdown-art-active-color);
  border-radius: 5px;
  padding: 3px 5px;
  margin: 3px;
  font-size: 0.5em;
  color: #d8a433;
}

.tab-section-dropbutton.expanded::after {
  transform: translateY(-50%) rotate(225deg);
  /* Rotate by 90 degrees */
}

.news-text-section {
  background: var(--news-text-color);
  margin: 10px;
  border-radius: 20px;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  /* Start with content hidden */
  transition: max-height 0.3s ease-in-out, opacity 0.2s ease-in-out;
}

.news-text-section.expanded {
  max-height: 500px;
  /* Adjust this value based on your content's potential maximum height */
  opacity: 1;
  /* Fully visible */
}


.tab-list {
  width: 50%;
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.tab-button {
  color: var(--button-text-color);
  background: #e1b045;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border: none;
  cursor: pointer;
  font-size: 24px;
  transform: scale(1);
  transition: all 0.3s ease;
}

.tab-button:hover {
  background: #e7b547;
}

.tab-button.active {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  /* Add shadow to the selected button */
  transform: scale(1.05);
  transition: all 0.3s ease;
  background: #f5c04d;
  border-radius: 10px;
}

.tab-content {
  background: var(--tab-content-color);
  padding: 20px;
  width: 50%;
  min-height: auto;
  max-height: 500px;
  overflow-y: scroll;
  text-align: center;
  border-bottom-left-radius: 20px;
  /* Adjust as needed */
  border-bottom-right-radius: 20px;
  /* Adjust as needed */
  scrollbar-color: #669488 #fff5ba00
}

.tab-content h3 {
  padding: 10px;
}

.tab-content h4 {
  color: #f5c04d;
  padding: 10px;
}

.tab-content p {
  padding: 10px;
  font-size: 18px;
}

.donate-profile {
  margin-top: 15px;
  width: 300px;
  height: 200px;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
}

.about-logo {
  aspect-ratio: 4 / 1;
  width: 200px;
  /* adjust as needed */
  margin: 5% auto;
  display: block;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.news-icon {
  width: 20px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.desktop-only-inline-flex {
  display: inline-flex !important;
}

.desktop-only {
  display: block !important;
}

.mobile-only {
  display: none;
}

.mobile-only-inline-flex {
  display: none;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  /* This centers the children along the horizontal line */
}


.pagination-items {
  width: 80%;
  max-width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.pagination button {
  padding: 10px 20px;
  background-color: #FCC62E;
  color: var(--color);
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.1);
}

.pagination button:active {
  background-color: #d7a92a;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.pagination button:disabled {
  background-color: #ebb00e7d;
  color: var(--color);
  cursor: not-allowed;
}

.pagination h3 {
  margin: 10px;
  background-color: var(--pagination-h3-color);
  padding: 20px 30px;
  border-radius: 50px;
}

.pagination i {
  font-size: 20px;
}

@keyframes popUpScale {
  0% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}


.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: var(--popup-outer);
  z-index: 10000;
}

.popup-inner {
  animation: popUpScale 0.15s ease-in-out 0s 1;
  min-height: 50%;
  max-height: 77%;
  overflow: auto;
  position: absolute;
  left: 30%;
  right: 30%;
  top: 10%;
  bottom: auto;
  margin: auto;
  border-radius: 20px;
  background: var(--popup-bg-color);
  backdrop-filter: blur(30px);
  /* Adjust the pixel value to control the blur intensity */
  -webkit-backdrop-filter: blur(30px);
  /* For Safari */
  padding: 18px 18px 0px 18px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.popup-inner ::-webkit-scrollbar {
  height: 0;
  display: none;
  width: 0;
}

.popup-inner .loading-section{
  width: 100%;
}



.popup-text {
  text-align: left;
  margin-left: 30px;
  margin-top: 10px;
}

.popup-text p {
  margin-top: 20px;
  margin-bottom: 20px;
}

.popup h3 {
  position: relative;
  top: 0;
  margin: 18px 0px;
  padding: 10px;
  min-width: 70%;
  max-width: 100%;
  border-radius: 20px;
  color: #65a594;
  background: var(--popup-on-bg-color);
}

.popup h1 {
  backdrop-filter: blur(30px);
  /* Adjust the pixel value to control the blur intensity */
  /* For Safari */
  position: sticky;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: 5px;
  top: 0;
  padding: 10px 10px 10px 22px;
  width: 100%;
  z-index: 100;
  background: var(--popup-header-color);
  border-radius: 16px;
  font-size: 1.5em;
}

.close-button i { 
  position: relative;
  right: 0;
  top: 0;
  background-color: var(--color);
  border-radius: 50px;
  border: none;
  height:fit-content;
  width: fit-content;
  cursor: pointer;
  font-size: medium;
  font-weight: bolder;
  box-shadow: 0 5px 10px rgba(117, 117, 117, 0.103);
  color: #E95C15;
  font-size: 1.9em;
}

.close-button:hover {
  color: #dc4f08;
}

.close-button:active {
  color: #b24007;
}

.slideshow {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 10px 0 0 0;
  overflow: hidden;
  min-height: 200px;
  max-height: 200px;
  min-width: 50%;
  max-width: 100%;
  /* hides the images that are out of view */
}

.slideshow img {
  aspect-ratio: 3/1;
  transition: transform 1s ease-out;
  object-fit: cover;
  object-position: 50% 33%;
  border-radius: 10px;
  min-height: 200px;
  max-height: 200px;
  min-width: 250px;
  max-width: 284px;
  /* transition effect */
  background-color: #ababab;
}

.image-container {
  z-index: 1;
  position: relative;
  transition: transform 1s ease-out;
  width: 300px;
    min-height: 200px;
  max-height: 200px;
}

.attributions-pane {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding: 12px 7px;
  position: absolute;
  width: 284px;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.723);
  animation: fadeInSlideshow 0.3s ease-in-out;
}

.attributions-pane p {
  display: inline-block;
  margin: 2px;
}

.mapbox-attribution {
  position: absolute;
  display: block;
  top: 150px;
  font-size: x-small;
  color: #f5c04d;
  width: 80%;
  margin-left: 30px;
}

.mapillary-attribution {
  position: absolute;
  display: block;
  top: 130px;
  font-size: x-small;
  color: #f5c04d;
  width: 80%;
  margin-left: 145px;
}

.mapillary-attribution svg {
  border-radius: 11px;
}

.mapbox-attribution {
  position: absolute;
  display: block;
  top: 150px;
  font-size: x-small;
  color: #f5c04d;
  width: 80%;
  margin-left: 30px;
}

.mapbox-attribution.card-attribution {
  top: 147px;
}

.mapbox-attribution.slideshow-attribution {
  top: 183px;
}

.mapillary-attribution.card-attribution {
  top: 130px;
  margin-left: 143px;
}

.mapillary-attribution.slideshow-attribution {
  top: 165px;
  margin-left: 160px;
}

.mapbox-attribution a {
  color: #9e7c33;
}

.attributions-pane i {
  margin: 5px;
  font-size: 1.2em;
  color: #ffffff68;
}

.attributions-pane a {
  color: #f5c04d;
}

.icon-overlay {
  z-index: 11;
  position: absolute;
  bottom: 5%;
  right: 6%;
  font-size: 1.6em;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.497);
  background-color: #ffffffbf;
  border: 1px solid rgba(132, 132, 132, 0.3);
  color: #00000073;
  border-radius: 50%;
  cursor: pointer;
  /* Hand cursor for click indication */
  transform: scale(1.1);
  transition: transform 0.3s ease;

  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-delay: 1.3s;
  animation-iteration-count: 1;
  animation-name: popUp-icon;
}


.icon-overlay:hover {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.634);
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

#erase-text-icon {
  position: relative;
  color: #7e7e7e5b;
  cursor: pointer;
  font-size: 32px;
}

#erase-text-icon-show {
  visibility: visible;
  position: relative;
  color: #7e7e7e8d;
  cursor: pointer;
  font-size: 32px;
}

#erase-text-icon-hide {
  visibility: hidden;
  position: relative;
  color: #7e7e7e8d;
  font-size: 32px;
}

@keyframes popUp {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }
}

@keyframes popUp-icon {

  0%,
  100% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1.25);
  }
}

@keyframes fadeInSlideshow {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}


.slideshow-prev-button,
.slideshow-next-button {
  z-index: 5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  padding: 7px 15px;
  background-color: #fcc52e5e;
  color: #fff;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.1);
}

.slideshow-prev-button i,
.slideshow-next-button i {
  font-size: 1.4em;
}

.slideshow-prev-button:active,
.slideshow-next-button:active {
  background-color: #fcc52ef6;
}

.slideshow-prev-button.enabled,
.slideshow-next-button.enabled {
  background-color: #fcc52ed7;
}

.slideshow-prev-button {
  left: 10px;
}

.slideshow-next-button {
  right: 10px;
}





@media screen and (max-width: 875px) {

  .popup-inner {
    left: 5.6%;
    right: 5.6%;
  }

  #location-button {
    margin-left: 10px;
  }

  /* Style The Dropdown Button */
  header .dropbtn {
    padding-right: 32px;
    min-width: 5vh;
    max-height: 2vh;
    font-size: 15px;
  }

  .slideshow {
    width: 300px;
    /* hides the images that are out of view */
  }

  #erase-text-icon-show {
    position: relative;
    color: #7e7e7e5b;
    cursor: pointer;
    font-size: 32px;
  }

  /* Links inside the dropdown */
  header .dropdown-content a {
    padding: 12px 8px;
    font-size: 15px;
    margin-right: 0px !important;
  }

  .desktop-only-inline-flex {
    display: none !important;
  }

  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    display: block;
  }

  .mobile-only-inline-flex {
    display: inline-flex;
  }

  .dropdown .dropbtn i {
    font-size: 20px;
    /* Size of the icon */
    margin-right: 12px;
    /* Space to the right of the icon */
  }


  header {
    background: var(--mobile-header-color);
    backdrop-filter: blur(10px);
    /* Adjust the pixel value to control the blur intensity */
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    align-items: center;
    z-index: 1000;
  }

  .tab-drop-header-items {
    font-size: 0.75em;
  }

  .see-more-section {
    padding-right: 0px;
  }

  .logo-image {
    aspect-ratio: 1 / 1;
    width: 100px;
    /* adjust as needed */
    margin-top: 5%;
    overflow: auto;
    display: inline-block;
  }

  .tab-section h3 {
    padding: 10px !important;
    font-size: 1.10em !important;
    border-radius: 20px;
  }

  #header-items {
    display: inline-flex;
    font-size: 1.5em;
    gap: 10px;
    width: 100%;
  }

  #header-items-links {
    display: inline-flex;
    flex-direction: column;
    text-align: left;
    width: 67%;
  }

  #header-items-options {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  #header-items-options p {
    font-weight: 600;
    font-size: x-small;
    margin-top: 30px;
    margin-bottom: 5px;
  }

  #header-items-options i {
    font-size: small;
    margin: 0;
  }

  #header-items-options p:first-child {
    font-weight: 600;
    font-size: x-small;
    margin-top: 0px;
  }

  #header-items-options p:last-child {
    font-weight: 600;
    font-size: x-small;
    margin-top: 30px;
    margin-bottom: 5px;
  }

  #header-items a {
    padding: 7px 40px;
    width: 100%;
    /* Adds space to the right of each link */
  }

  #header-items a i {
    padding: 10px;
    border-radius: 12px;
    margin-right: 10px;
    background-color: var(--mobile-header-links-icons);
  }

  #header-items-divider {
    position: relative;
    width: 70%;
    height: 1px;
    margin-left: 40px;
    background-color: var(--filter-sheet-header-divider);
  }

  /* Styling for even links */
  #header-items a:first-child {
    padding-top: 18px;
    /* Replace with your desired color for even items */
  }

  /* Styling for odd links */
  #header-items a:last-child {
    padding-bottom: 18px;
    /* Replace with your desired color for odd items */
  }

  #main-title h2 {
    margin-bottom: 20px;
    font-size: 0.8em;
    padding-right: 5px;
  }

  #sliding-header h2 {
    font-size: 0.8em;
  }

  .search-inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .search-items {
    justify-content: stretch;
    flex-direction: row;
  }

  #search-divider {
    width: 100%;
    height: 1px;
    margin: 12px 0px;
  }

  #search-button {
    position: absolute;
    margin-top: 20px;
    left: 20%;
    right: 20%;
    margin: 20px auto 20px;
  }

  #search-input-combined {
    margin-top: 20px;
    display: block;
  }

  #search-input-art,
  #search-input-artist {
    width: 55%;
    margin-left: 20px;
  }

  #search-input-location {
    width: 67%;
  }

  .dropbtn {
    min-width: 70px;
    min-height: 50px;
  }

  .dropdown-content a {
    padding: 15px 10px;
  }

  .dropdown-content {
    margin: auto;
  }

  #current-search-query-online,
  #current-search-query-offline {
    margin-top: 30px;
    margin-bottom: 5px;
  }

  #current-search-query-online h2 {
    font-size: 12px;
  }

  #current-search-query-offline h2 {
    margin-bottom: 7px;
    font-size: 12px;
    background-color: rgba(206, 16, 16, 0.62);

    animation-duration: 0.7s;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    animation-delay: 0.4s;
    animation-iteration-count: 1;
    animation-name: popUp;
  }

  .no-results-section ::-webkit-scrollbar {
    height: 0;
    display: none;
    height: 0;
  }

  .recommendations ::-webkit-scrollbar {
    height: 0;
    display: none;
    height: 0;
  }

  .no-results-section {
    margin-top: 0px;
    display: inline-block;
    width: 100%;
    flex-direction: row;
  }

  .no-results-section h3 {
    margin-bottom: 5px;
    font-size: 16px;
  }

  .filter-section h3 {
    font-size: 20px;
    margin: 10px;
    min-width: 190px;
  }

  .filter-section .filters {
    flex-direction: column;
    overflow-x: scroll;
  }

  .filter-section ::-webkit-scrollbar {
    height: 0;
  }

  .filter-section {
    margin-top: 0px;
    display: inline-block;
    width: 100%;
    flex-direction: row;
  }

  .filter-section h3 {
    font-size: 20px;
    margin: 10px;
    min-width: 190px;
  }

  .filter-section .filters {
    flex-direction: column;
    overflow-x: scroll;
  }

  .tab-section {
    padding-top: 10%;
    padding-bottom: 5%;
  }

  .tab-list {
    width: 85%;
  }

  .tab-button {
    background: #e1b045;
    width: 100%;
    padding: 7px;
    font-size: 14px;
  }

  .tab-button.active {
    border-radius: 5px;
  }

  .tab-content {
    padding: 20px;
    width: 85%;
    height: 20%;
    max-height: 400px;
  }

  .tab-content h3 {
    padding: 3px;
    font-size: 17px;
  }

  .tab-content p {
    padding: 12px;
    font-size: 14px;
  }

  #svg-background {
    top: 5px;
    background-position: 23%;
  }

  .donate-profile {
    width: 150px;
    height: 120px;
  }

  .popup-text {
    text-align: left;
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .popup-text p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  footer h3 {
    padding-left: 0px !important;
    font-size: 0.9em !important;
  }

  .cookie-banner {
    max-width: 80% !important;
    min-width: 70% !important;
    position: fixed;
    bottom: 15px;
    ;
    left: 10% !important;
    right: 10% !important;
    background-color: var(--cookies-bg-color);
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    /* Ensuring the banner is displayed above other page elements */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 5px 50px rgb(0, 0, 0.755);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
  }

  .install-app-banner {
    max-width: 80% !important;
    min-width: 70% !important;
    position: fixed;
    bottom: 15px;
    ;
    left: 10% !important;
    right: 10% !important;
    background-color: var(--cookies-bg-color);
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    /* Ensuring the banner is displayed above other page elements */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 5px 50px rgb(0, 0, 0.755);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
  }

  /* When the banner is active, it will fade in */
  .cookie-banner.active {
    opacity: 1;
    visibility: visible;
  }


  .cookie-banner p {
    text-align: center;
    flex: 1;
    margin-right: 10px !important;
    margin-bottom: 0px !important;
    font-size: x-small !important;
    line-height: 1.5;
  }

  .cookie-banner h3 {
    text-align: center;
    flex: 1;
    margin-bottom: 10px;
  }

  .cookie-banner-items {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cookie-banner-options-text {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 70%;
  }

  .cookie-banner-option-items {
    padding: 5px 0px !important;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }


  .cookie-banner button {
    padding: 10px 20px;
    margin: 5px 5px !important;
    width: 90% !important;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f5c04d;
    transition: background-color 0.2s;
    text-align: center;
    font-weight: 600;
    font-size: large;
  }

  #options-divider {
    background-color: #47927E;
    width: 66%;
    height: 1px;
    margin: 5px 30% 5px 10% !important;
  }

  .add-filter-button {
    width: 80px !important;

    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
    font-size: 14px !important;
    font-weight: 600;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px 15px !important;
  }


  .filter-sheet {
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
    overflow-y: hidden !important;
    /* Ensure content is scrollable */
  }

  .apply-filters-button {
    right: 20% !important;
    left: 20% !important;
    width: 60% !important;
  }

  .filter-sheet-content {
    margin: 30px 10px 30px 10px !important;
  }

  .filter-sheet-content-header {
    margin-top: 0px !important;
  }

  .filter-chip {
    padding: 6px 12px !important;
    border: 1px solid #949494;
    border-radius: 16px;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.3s ease;
  }

  .filter-chip:active {
    transform: scale(1.075) !important;
  }

  .filter-categories {
    margin-top: 0px !important;
  }

  .info-section {
    display: inline-flex !important;
    flex-direction: column !important;
  }

  .feedback-form {
    padding: 0 20px !important;
    width: 100% !important;
    height: 350px !important;
  }

  .social-share {
    padding: 0 20px !important;
    width: 100% !important;
  }

  .third-section {
    height: 250px !important;
    padding: 0 50px !important;
    width: 100% !important;
  }

  .third-section p {
    font-size: small;
  }

  .send-form {
    width: 85% !important;
    height: 50% !important;
  }

  .copy-button {
    width: 40px !important;
    height: 40px !important;
    font-size: 1.4em !important;
  }

  .feedback-form p {
    padding: 0 10px !important;
  }

  .install-app-banner {
    max-width: 80% !important;
    min-width: 70% !important;
    position: fixed;
    bottom: 15px;
    ;
    left: 10% !important;
    right: 10% !important;
    background-color: var(--cookies-bg-color);
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    /* Ensuring the banner is displayed above other page elements */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 5px 50px rgb(0, 0, 0.755);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
  }

  .main-title-heading {
    display: inline-flex !important;
  }

  .tooltip-content {
    left: -100% !important;
  }
  
  .tooltip-content::after {
    left: 65% !important;
  }
}

.switch-section {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.switch-section i {
  color: var(--footer-icon-color);
}

/* The switch - the box around the slider */
.switch {
  margin: 0 7px;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eeeeee;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #f5c04d;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #323232;
}

input:focus+.slider {
  box-shadow: 0 0 0px #f5c04d;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

footer h3 {
  padding-left: 0px;
  margin: auto;
}

footer {
  display: flex;
  background: var(--footer-bg-color);
  color: var(--footer-text-color);
  text-align: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 50px;
  padding: 27px;
  width: 100%;
  margin-top: auto;
}

.timeline {
  position: relative;
  display: block;
  max-width: 600px;
  padding: 30px 0 70px 0;
  margin-top: 6px;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background: linear-gradient(to bottom, transparent, #ddd 15%, #ddd 85%, transparent);
  top: 0;
  bottom: 0;
  left: 50%;
}

.timeline-item {
  padding: 0 15px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

.timeline-item::before {
  content: ' ';
  position: absolute;
  width: 12px;
  height: 12px;
  left: calc(100% - 7px);
  background-color: white;
  border: 4px solid #ddd;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.timeline-item-left {
  margin-top: 20px;
  text-align: end;
  left: 0;
}

.timeline-item-right {
  margin-top: 20px;
  text-align: start;
  left: calc(50% + 9px);
}

.timeline-item-right::before {
  left: -16px;
}

.timeline-content {
  top: 7px;
  width: 100%;
  position: relative;
  border-radius: 6px;
}

.timeline-content h4 {
  margin-top: 0;
}

.chip-compartment {
  display: inline-block;
  justify-content: start;
  gap: 10px;
  width: 100%;
  overflow-x: scroll;
  padding: 5px 10px;
}

.chip {
  display: inline-flex;
  text-align: center;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 20px;
  color: var(--popup-bg-color);
  background-color: #88a89f;
  margin-right: 5px;
  /* Adjust the value as needed */
  margin-bottom: 5px;
  /* Adjust the value as needed */
}

.cookie-banner {
  max-width: 500px;
  min-width: 400px;
  position: fixed;
  bottom: 15px;
  ;
  left: 33%;
  right: 33%;
  background-color: var(--cookies-bg-color);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  /* Ensuring the banner is displayed above other page elements */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 5px 50px rgb(0, 0, 0.755);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}



/* When the banner is active, it will fade in */
.cookie-banner.active {
  opacity: 1;
  visibility: visible;
}

.cookie-banner p {
  text-align: center;
  flex: 1;
  margin-right: 20px;
  font-size: 14px;
  line-height: 1.5;
}

.cookie-banner h3 {
  text-align: center;
  flex: 1;
  font-weight: 900;
  margin-bottom: 10px;
}

.cookie-banner-items {
  margin-top: 10px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.cookie-banner-options-text {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  max-width: 77%;
}

.cookie-banner-options-text p {
  margin-left: 20px;
}

.cookie-banner-options-text h6 {
 font-weight: 600;
 font-size: 0.8em;
}



.cookie-banner-option-items {
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cookie-banner a {
  color: #f5c04d;
}

.tab-section a {
  color: #f5c04d;
}


.cookie-banner button {
  padding: 10px 20px;
  margin: 10px 10px;
  width: 200px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f5c04d;
  color: var(--cookies-bg-color);
  transition: background-color 0.2s;
  text-align: center;
  font-weight: 600;
}

.cookie-banner button:hover {
  background-color: #f3bb42;
}

.cookie-banner button:active {
  background-color: #bd9640;
}

.cookie-banner button:last-child {
  background-color: #88a89f;
}

.cookie-banner button:last-child:hover {
  background-color: #7ba79a;
}

.cookie-banner button:last-child:active {
  background-color: #627972;
}

/* The switch - the box around the slider */
.options-switch {
  margin: 0 7px;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.options-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.options-slider {
  position: absolute;
  cursor: pointer;
  width: 60px;
  height: 34px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--tab-on-bg-color);
  -webkit-transition: .4s;
  transition: .4s;
}

.options-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--color);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.options-slider {
  background-color: #f5c04d;
}

input:focus+.options-slider {
  box-shadow: 0 0 0px #f5c04d;
}

input:checked+.options-slider:before {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

/* Rounded sliders */
.options-slider.round {
  border-radius: 34px;
}

.options-slider.round.disabled {
  background-color: #c29b46;
  color: #997f48;
  cursor: not-allowed;
}

.options-slider.round:before {
  border-radius: 50%;
}

#options-divider {
  background-color: #47927E;
  width: 66%;
  height: 1px;
  margin: 5px 30% 5px 10%;
}

.add-filter-button {
  display: inline-flex;
  gap: 3px;
  position: relative;
  background-color: var(--on-bg-color);
  border: 1px solid #ffc115;
  color: #ffc115;
  width: 100px;
  height: 25px;
  padding: 18px 15px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transform: scale(1);
  transition: transform 0.3s ease;

  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-delay: 2.4s;
  animation-iteration-count: 1;
  animation-name: popUp;
}

.add-filter-button:active {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

.filter-sheet.open {
  bottom: 0;
}

.filter-sheet-header {
  display: inline-flex;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  z-index: 33;
  padding: 8px 26px;
  border-bottom: 1px solid var(--filter-sheet-header-divider);
  overflow-y: hidden;
  /* Ensure content is scrollable */
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.filter-sheet-handle-item {
  position: relative;
  height: 3px;
  width: 100%;
  background-color: rgb(164, 164, 164);
  border-radius: 20px 20px 0 0;
}

.filter-sheet-handle {
  position: relative;
  height: 40px;
  width: 20%;
  left: 40%;
  margin-top: 5px;
  cursor: grab;
}

.filter-sheet-header i {
  color: #E95C15;
  position: relative;
  font-size: 2.5em;
  left: 70%;
  cursor: pointer;
}

.filter-sheet {
  border-radius: 30px 30px 0 0;
  z-index: 9999;
  position: fixed;
  bottom: -150%;
  left: 25%;
  right: 25%;
  width: 50%;
  background-color: var(--filter-sheet-bg-color);
  box-shadow: 0px -2px 50px rgba(0, 0, 0, 0.517);
  transition: bottom 0.3s ease-in-out;
  /* Optional: Disable interactions with items in the div */
  padding: 5px;
  overflow-y: scroll;
  /* Ensure content is scrollable */
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.filter-sheet::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

.filter-bottom-sheet.open {
  bottom: 0;
}

.MuiSlider-root {
  color: #f5c04d;
  /* Adjust color to your theme */
  margin-bottom: 5px;
}

.MuiSlider-thumb {
  color: #f5c04d;
  /* Adjust color to your theme */
}

.MuiSlider-track {
  color: #f5c04d;
  /* Adjust color to your theme */
}

.MuiSlider-rail {
  color: #f5c04d;
  /* Adjust color to your theme */
}

.MuiSlider-valueLabel {
  color: #fff;
  /* White color for the text inside the value label */
  background-color: var(--filter-sheet-slider-label) !important;
  /* Same color as your thumb and track */
  border-radius: 10px !important;
}

/* Style the value label's thumb */
.MuiSlider-valueLabel>span>span {
  color: #f5c04d;
  /* Same color as your thumb and track */
}

/* Style the value label's thumb */
.MuiSlider-valueLabelOpen>span>span {
  color: #f5c04d;
  /* Same color as your thumb and track */
}

/* Style the thumb on hover */
.MuiSlider-thumb:hover {
  box-shadow: 0px 0px 5px 5px rgba(245, 192, 77, 0.3);
  /* Add a subtle shadow around the thumb */
}

/* Style the thumb on hover */
.MuiSlider-thumb:hover {
  box-shadow: 0px 0px 0px 8px rgba(245, 192, 77, 0.161) !important;
  /* Add a subtle shadow around the thumb */
}

/* Style the thumb when it's being focused */
.MuiSlider-thumb.Mui-focusVisible {
  box-shadow: 0px 0px 5px 5px rgba(245, 192, 77, 0.5) !important;
  /* Add a more prominent shadow when focused */
}

.MuiSlider-thumb.Mui-active {
  box-shadow: 0px 0px 0px 12px rgba(245, 192, 77, 0.236) !important;
  /* Add a more prominent shadow when focused */
}

.filter-categories {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.filter-sheet-content {
  display: flex;
  flex-direction: column;
  margin: 20px 20px 50px 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.filter-sheet-content h3 {
  border-radius: 10px;
  padding: 5px 5px;
  width: fit-content;
  color: #65a594;
  background-color: var(--filter-sheet-content-h3);
}

.filter-sheet-content-header {
  margin-top: 15px;
  margin-bottom: 5px;
  align-items: center;
  display: inline-flex;
  gap: 10px;
}

.change-value-button {
  border: none;
  font-size: large;
  font-weight: bold;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  color: var(--filter-sheet-bg-color);
  background-color: #f5c04d;
  cursor: pointer;
}

.filter-chip {
  padding: 8px 16px;
  border: 1px solid var(--filter-sheet-chip-border);
  border-radius: 16px;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.filter-chip:hover {
  border: 1px solid #47927E;
}

.filter-chip:active {
  transform: scale(1.3);
  transition: transform 0.3s ease;
}

.filter-chip.selected {
  transition: transform 0.3s ease;
  background-color: #f5c04d;
  border: 1px solid #f5c04d;
  color: var(--filter-sheet-chip-color)
}

.apply-filters-button {
  background-color: #47927E;
  bottom: -100%;
  right: 42%;
  left: 42%;
  position: fixed;
  border: none;
  color: var(--filter-sheet-bg-color);
  height: 40px;
  width: 16%;
  border-radius: 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.442);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.apply-filters-button.open {
  bottom: 20px;
  opacity: 1;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-name: popUpFilterButton;
  position: fixed;
}

@keyframes popUpFilterButton {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

.body-no-scroll {
  overflow: hidden !important;
  height: 100%;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.164);
  z-index: 9998;
  /* Ensure it's below your sheet but above other content */
}

.progress-bar {
  width: 150px;
  background-color: var(--progress-bar-bg);
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 0;
}

.progress-bar-fill {
  height: 5px;
  background-color: #47927E;
  transition: width 1s ease-in-out;
}

/* FeedbackForm.css */

.feedback-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45%;
  height: 300px;
  background-color: rgba(124, 124, 124, 0);
  text-align: center;
  padding-bottom: 16px;
}

.feedback-form p {
  padding: 0 50px;
}

.feedback-form h2 {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.feedback-input {
  width: 100%;
  height: 65%;
  padding: 13px 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  background-color: rgba(157, 157, 157, 0.13);
  color: var(--color-text);
  border: none;
  text-decoration: none;
  border-radius: 8px;
  resize: none;
}

.clipboard-input {
  width: 65%;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  background-color: rgba(157, 157, 157, 0.13);
  color: rgb(161, 161, 161);
  border: none;
  text-decoration: none;
  border-radius: 5px;
  resize: none;
}

.clipboard-input:focus {
  outline: none;
  /* Removes the default focus outline (optional) */
  border-color: transparent;
}

.feedback-input:focus {
  outline: none;
  /* Removes the default focus outline (optional) */
  border-color: transparent;
}

.feedback-input::placeholder {
  color: #aaa;
}

.send-button,
.copy-button {
  background-color: #FCC62E;
  color: var(--filter-sheet-chip-color);
  border: none;
  width: 150px;
  height: 43px;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bolder;
  transition: all 0.3s ease;
}

.send-button {
  margin-left: 30px;
}

.send-loading-button {
  margin-left: 30px;
  background-color: #fcd158;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--filter-sheet-chip-color);
  border: none;
  width: 150px;
  height: 43px;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 8px;
  cursor: not-allowed;
}

.send-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 75%;
  height: 65%;
}

.send-loading-spinner {
  position: relative;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top: 3px solid #F48E2A;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

.send-button:hover,
.copy-button:hover {
  background-color: #e6af17;
  transition: all 0.3s ease;
}


/* SocialShare.css */

.social-share {
  width: 33%;
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background-color: rgba(124, 124, 124, 0.065);
}

.social-share-items {
  width: 100%;
  height: 33%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.clipboard-share {
  justify-content: center;
  align-items: center;
  display: inline-flex;
  gap: 10px;
}

.react-share__ShareButton {
  background-color: var(--header-drop-content-color) !important;
  color: #FCC62E !important;
  border: none !important;
  font-size: 30px !important;
  height: 50px;
  width: 50px;
  cursor: pointer !important;
  border-radius: 10px !important;
  transform: scale(1) !important;
  transition: transform 0.3s ease;
}

.react-share__ShareButton:hover {
  transform: scale(1.1) !important;
  transition: transform 0.3s ease !important;
}

.react-share__ShareButton:active {
  background-color: var(--header-drop-hover-color) !important;
}

.copy-button {
  background-color: #FCC62E;
  color: var(--filter-sheet-chip-color);
  border: none;
  padding: 5px 10px;
  height: 40px;
  width: 150px;
  font-size: 14px;
  cursor: pointer;
  font-weight: bolder;
  cursor: copy;
}

.copy-button.success {
  background-color: #47927E;
  /* Green color for success */
}

.send-button.success {
  background-color: #47927E;
  /* Green color for success */
}

.send-button.error {
  background-color: #9e1717;
  /* Green color for success */
}

.third-section {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33%;
  height: 300px;
  background-color: rgba(124, 124, 124, 0.038);
  font-size: larger;
  font-weight: 200;
}

.third-section-divider {
  margin-top: 10px;
  margin-bottom: 30px;
  width: 75%;
  height: 1px;
  background-color: #47927E;
}

.info-section {
  display: inline-flex;
  display: flex;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--popup-bg-color);
}



.install-app-banner {
  max-width: 500px;
  min-width: 400px;
  position: fixed;
  bottom: 15px;
  ;
  left: 33%;
  right: 33%;
  background-color: var(--cookies-bg-color);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  /* Ensuring the banner is displayed above other page elements */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 5px 50px rgb(0, 0, 0.755);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}

/* When the banner is active, it will fade in */
.install-app-banner.active {
  opacity: 1;
  visibility: visible;
}


.install-app-banner p {
  text-align: center;
  flex: 1;
  margin-right: 20px;
  margin-bottom: 10px !important;
  font-size: medium !important;
  line-height: 1.5;
}

.install-app-banner h3 {
  text-align: center;
  flex: 1;
  margin-bottom: 10px;
}


.install-app-button {
  padding: 10px 20px;
  margin: 5px 5px !important;
  width: 90% !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f5c04d;
  color: var(--color);
  transition: background-color 0.2s;
  text-align: center;
  font-weight: 600;
  font-size: large;
}

.install-app-button:active {
  background-color: #dbab44;
}

.install-app-button:hover {
  background-color: #ffbd30;
}

.no-install-app-button:active {
  background-color: #878787;
}

.no-install-app-button:hover {
  background-color: #c4c4c4;
}

.no-install-app-button {
  padding: 7px 15px;
  margin: 5px 5px !important;
  width: 70% !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ababab;
  color: var(--color);
  transition: background-color 0.2s;
  text-align: center;
  font-weight: 600;
  font-size: medium;
}

.steps-number {
  background-color: #47927E;
  color: var(--cookies-bg-color);
  border-radius: 20px;
}

.nav-bar-menu-button {
  position: relative;
  background-color: var(--header-drop-color);
  border-radius: 10px;
  padding: 16px 14px;
  font-size: x-large;
  margin-left: 0;

  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-name: popUpFilterButton;
}

.nav-bar {
  position: absolute;
  left: 0;
  width: 100%;
  height: fit-content;
  max-height: 0; /* Set max-height to 0 for collapsing */
  background-color: var(--nav-bar-bg);
  top: 67px;
  display: flex; /* Use flex instead of inline-flex for full width */
  justify-content: stretch;
  align-items: stretch;
  z-index: 999;
  overflow: hidden; /* Important: hides content that exceeds max-height */
  transition: max-height 0.3s ease-in-out; /* Animation effect */
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
}

.nav-bar.show {/* Important: hides content that exceeds max-height */
  max-height: 300px; /* Set a reasonable max-height value for expanding */
}

.main-title-heading {
  display: none;
  align-items: center;
  justify-content: center;
  color: var(--main-title-heading) !important;
  position: relative;
  font-weight: 900;
  font-size:xx-large; 
  margin: 30px 10px 5px 10px;
  background-color: var(--main-title-heading-bg);
  border-radius: 20px;
  padding: 10px 20px;
}

.scroll-down-button {
  cursor: pointer;
  display: inline-block;  
  font-size: 1.5em;
  position: sticky; 
  bottom: 5%;
  width: fit-content;
  padding: 13px 19px;
  border-radius: 50px;
  background-color: #4b9a85d6;
  color: var(--popup-bg-color);
  margin-left: 80%;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.281);
  /* Other styling as needed, like z-index, colors, etc. */
  opacity: 1;

  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: popUp;

  transition: opacity 1s;

}

.scroll-down-button.hide {
  opacity: 0;
  padding: 5px 5px;
  transition: opacity, padding 1s;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.randomize-recommendations-button {
  background-color: #689388d1;
  border: none;
  color: #ffce64;
  padding: 10px 12px;
  margin: 4px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transform: scale(1);
  transition: all 0.3s ease;
  font-weight: 900;
}

.randomize-recommendations-button:active {
  background-color: #397162d1;
  color: #ffb922;
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
}

.send-button-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.tooltip-container i {
  border-radius: 50px;
  background-color: rgba(51, 51, 51, 0.822);
  color:rgba(255, 255, 255, 0.719);
  font-size: 1.5em;
}

.tooltip-content {
  display: block;
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #7d7d7d;
  color: var(--color);
  padding: 10px;
  border-radius: 6px;
  width: 220px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  pointer-events: none;
  z-index: 1;
  transition: opacity 0.3s ease;
}

.tooltip-content.hide {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tooltip-content::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  opacity: 0.9;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #8a8a8a transparent transparent transparent;
  transition: opacity 0.3s ease;
}



